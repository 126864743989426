'use client';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { differenceInCalendarDays } from 'date-fns';

import PriceTypeMessage from '@/components/PriceTypeMessage';
import { Skeleton } from '@/components/Skeleton';
import { selectServerISOtime } from '@/ducks/common/lookup/selectors';
import { useUIResource } from '@/ducks/common/resources';
import {
  selectConfigServiceFeatureFlags,
  selectConfigServiceUplift,
  selectUpliftSupportedCurrencies,
} from '@/ducks/common/settings';
import { useCurrencyCode } from '@/ducks/currencyCode';
import { selectSecondarySailors } from '@/ducks/travelParty/selectors';
import { buildTripInfo, isCurrencySupported, loadTripInfo } from '@/features/uplift/adapter';
import { selectUpliftIsReady } from '@/features/uplift/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { currentNYTime, parse } from '@/helpers/util/dateUtil';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import UpliftTooltipIcon from '../Icon/UpliftTooltipIcon';
import UIResource from '../UIResource';

import './Uplift.scss';

const defaultSkeletonProps = {
  height: '21px',
  width: '150px',
};

const {
  tracker: { createTracker },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({});

const Uplift = ({
  ariaLabel,
  cabinName,
  className,
  currencyCode,
  customIcon,
  flyout,
  hasPriceTypeLabel,
  insuranceAmount,
  isHorizontal,
  isLoadUpliftDisabled,
  isModalDisabled,
  isTaxesIncluded,
  isTooltipDisabled,
  onTrack,
  price,
  priceType,
  primarySailor,
  sailing,
  showOrFromLabel,
  skeletonProps,
  trackerEventCategory,
  upliftPriceType,
}) => {
  const serverISOtime = useAppSelector(selectServerISOtime);
  const upliftSettings = useAppSelector(selectConfigServiceUplift);
  const isUpliftReady = useAppSelector(selectUpliftIsReady);
  const additionalSailors = useAppSelector(selectSecondarySailors);
  const priceWithCents = Math.ceil(price * 100);
  const insuranceWithCents = insuranceAmount * 100;
  const [upliftPrice, setUpliftPrice] = useState('');

  useEffect(() => {
    if (!isLoadUpliftDisabled && isUpliftReady) {
      const tripInfo = buildTripInfo(
        priceWithCents,
        insuranceWithCents,
        sailing,
        primarySailor,
        cabinName,
        additionalSailors,
      );
      setTimeout(() => loadTripInfo(tripInfo), 200);
    }
  }, [isLoadUpliftDisabled, isUpliftReady, price, primarySailor]);

  const assignPriceSpanObserver = useCallback((element) => {
    const priceSpanMutationObserver = new MutationObserver((mutations) => {
      const addedRecord = (mutations || []).find((record) => record.addedNodes?.length);
      const priceNodeInnerText = addedRecord.target?.innerText;
      !!priceNodeInnerText?.length && setUpliftPrice(priceNodeInnerText);
    });
    if (!element) return;
    priceSpanMutationObserver.observe(element, { childList: true });
    return () => {
      priceSpanMutationObserver.disconnect();
    };
  }, []);

  const fromLabel = useUIResource(showOrFromLabel ? 'uplift.startPrice' : 'ItineraryRefinementCard.from');
  const monthText = useUIResource('uplift.month');

  if (
    !isCurrencySupported(currencyCode) ||
    !price ||
    price < upliftSettings?.minimumPrice?.[currencyCode] ||
    price > upliftSettings?.maximumPrice?.[currencyCode]
  ) {
    return null;
  }

  const minimumDays = upliftSettings?.minimumDays?.[currencyCode];
  if (sailing && minimumDays) {
    let sailingStartDate;
    if ('startDate' in sailing) {
      sailingStartDate = sailing.startDate;
    } else {
      sailingStartDate = sailing.embarkDate;
    }

    const currentDate = currentNYTime(serverISOtime);
    const daysUntilSailing = differenceInCalendarDays(parse(sailingStartDate), currentDate);
    if (daysUntilSailing < minimumDays) {
      return null;
    }
  }

  const trackUplift = () => {
    const label = `${trackerConstants.EVENT_LABELS.UPLIFT_INFO}${flyout ? `|${flyout}` : ''}`;
    tagmanager.tracker.common.trackIconClick({
      category: trackerEventCategory,
      label,
    });
    if (!isModalDisabled) {
      trackEvent({ action: 'displayed', module: 'uplift-price-context-pop-up' });
    }

    onTrack?.();
  };

  const onKeyDown = (e) => {
    imitateClickOnKeyEvent(e);
    trackUplift();
  };

  const currencySymbol = getSymbolFromCurrenciesData(currencyCode);

  const additionalProps = {};
  if (isModalDisabled) {
    additionalProps['data-up-disable-lightbox'] = true;
  }
  if (isTooltipDisabled) {
    additionalProps['data-up-disable-tooltip'] = true;
  }
  if (isTaxesIncluded) {
    additionalProps['data-up-taxes-included'] = true;
  }

  const dataUpPriceType = priceType === FiltersPriceType.perSailor ? 'per_person' : 'total';

  const classes = cn(className, 'uplift', {
    'uplift-font': showOrFromLabel,
  });

  const {
    baseColor: skeletonBaseColor,
    height: skeletonHeight,
    highlightColor: skeletonHighlightColor,
    width: skeletonWidth,
  } = skeletonProps;
  return (
    <span
      aria-label={`${ariaLabel || ''} ${fromLabel} ${upliftPrice} ${currencyCode}${monthText}`}
      className={classes}
      data-up-price-model={dataUpPriceType}
      data-up-price-type={upliftPriceType}
      data-up-price-value={priceWithCents}
      onKeyDown={onKeyDown}
      onMouseDown={trackUplift}
      onTouchStart={trackUplift}
      role="button"
      tabIndex="0"
      // Need to spread props because if the prop is present with false value it still takes effect
      {...additionalProps}
    >
      {isHorizontal ? (
        <>
          {!upliftPrice && (
            <Skeleton
              baseColor={skeletonBaseColor}
              height={skeletonHeight}
              highlightColor={skeletonHighlightColor}
              width={skeletonWidth}
            />
          )}
          <div className={cn({ hidden: !upliftPrice }, 'upliftPriceInfo')}>
            <span className="uplift__from">{fromLabel}</span>
            <span className="modal-linkContainer">
              <span className="modal-link">
                {currencySymbol}
                <span className="uplift__price">
                  <span className="price" data-up-from-currency-unit-major="" ref={assignPriceSpanObserver} />
                  <span className="month">{monthText}</span>
                </span>
                <span className="tooltip" data-up-tooltip="">
                  {customIcon || <UpliftTooltipIcon />}
                </span>
              </span>
            </span>
          </div>
        </>
      ) : (
        <>
          {!upliftPrice && (
            <Skeleton
              baseColor={skeletonBaseColor}
              height={skeletonHeight}
              highlightColor={skeletonHighlightColor}
              width={skeletonWidth}
            />
          )}
          <div
            aria-label={`See uplift financing pricing from ${currencySymbol}${upliftPrice}/month`}
            className={cn({ hidden: !upliftPrice })}
          >
            <span className="flex-price">
              {hasPriceTypeLabel && (
                <>
                  <PriceTypeMessage priceType={priceType} />{' '}
                </>
              )}
              {fromLabel}{' '}
              <span className="modal-link">
                {currencySymbol}
                <span>
                  <span className="price" data-up-from-currency-unit-major="" ref={assignPriceSpanObserver}>
                    <Skeleton inline width="20px" />
                  </span>
                  <UIResource id="uplift.month" />
                </span>
              </span>
            </span>
            <span aria-label="uplift modal tooltip" className="tooltip" data-up-tooltip="">
              {customIcon || <UpliftTooltipIcon />}
            </span>
          </div>
        </>
      )}
    </span>
  );
};

Uplift.propTypes = {
  cabinName: PropTypes.string,
  currencyCode: PropTypes.string,
  customIcon: PropTypes.node,
  flyout: PropTypes.string,
  insuranceAmount: PropTypes.number,
  isHorizontal: PropTypes.bool,
  isLoadUpliftDisabled: PropTypes.bool,
  isModalDisabled: PropTypes.bool,
  isTaxesIncluded: PropTypes.bool,
  isTooltipDisabled: PropTypes.bool,
  onTrack: PropTypes.func,
  price: PropTypes.number,
  priceType: PropTypes.string,
  primarySailor: PropTypes.shape({}),
  sailing: PropTypes.shape({
    embarkDate: PropTypes.string,
    startDate: PropTypes.string,
  }),
  showOrFromLabel: PropTypes.bool,
  skeletonProps: PropTypes.shape({
    baseColor: PropTypes.string,
    height: PropTypes.string,
    highlightColor: PropTypes.string,
    width: PropTypes.string,
  }),
  trackerEventCategory: PropTypes.string,
  upliftPriceType: PropTypes.oneOf(['cruise_option', 'total']),
};

Uplift.defaultProps = {
  cabinName: '',
  configServiceData: {},
  currencyCode: 'USD',
  customIcon: '',
  flyout: null,
  insuranceAmount: 0,
  isHorizontal: false,
  isLoadUpliftDisabled: false,
  isModalDisabled: false,
  isTaxesIncluded: false,
  isTooltipDisabled: true,
  onTrack: null,
  price: '',
  priceType: FiltersPriceType.perCabin,
  primarySailor: {},
  sailing: {},
  showOrFromLabel: true,
  skeletonProps: defaultSkeletonProps,
  trackerEventCategory: null,
  upliftPriceType: 'total',
};

export const UpliftWithSkeleton = (props) => {
  const { className, isShowUplift, showOrFromLabel, skeletonProps } = props;
  const classes = cn(className, 'uplift', {
    'uplift-font': showOrFromLabel,
  });
  const {
    baseColor: skeletonBaseColor,
    height: skeletonHeight,
    highlightColor: skeletonHighlightColor,
    width: skeletonWidth,
  } = skeletonProps;
  const isUpliftEnabled = Boolean(useAppSelector(selectConfigServiceFeatureFlags)?.uplift);
  const isUpliftReady = useAppSelector(selectUpliftIsReady);
  const currencies = useAppSelector(selectUpliftSupportedCurrencies);
  const { currencyCode } = useCurrencyCode();
  const isUpliftCurrencySupported = useMemo(() => currencies.includes(currencyCode), [currencies, currencyCode]);

  if (!isUpliftEnabled || !isUpliftCurrencySupported) return;

  if (!isShowUplift || !isUpliftReady) {
    return (
      <span className={classes}>
        <Skeleton
          baseColor={skeletonBaseColor}
          height={skeletonHeight}
          highlightColor={skeletonHighlightColor}
          width={skeletonWidth}
        />
      </span>
    );
  }
  return <Uplift {...props} className={classes} />;
};

UpliftWithSkeleton.defaultProps = {
  isShowUplift: false,
  showOrFromLabel: true,
  skeletonProps: defaultSkeletonProps,
};

export default Uplift;
