/* eslint-disable perfectionist/sort-objects */
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import type { TCurrencyCode } from '@/infra/types/common/base';
import type { TOptional } from '@/types/common';

import { CURRENCY_CODE_COOKIE } from '@/constants/settings';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { useCookieValue } from '@/ducks/cookies/hooks';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { getFilterAgencyCurrencyCode, getFilterAgentDefaultCurrency } from '@/ducks/fm/getters';
import { useMNVV } from '@/ducks/mnvv/hooks';
import { selectMnvvCurrencyCode } from '@/ducks/mnvv/selectors';
import { selectInvoiceCurrencyCode } from '@/ducks/pages/summary/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { useAppSelector } from '@/store';

import { CURRENCY_CODE_SOURCE } from './types';

export type TCurrencySourcePriorities = Partial<Record<CURRENCY_CODE_SOURCE, number>>;

// The currency codes are arranged by priority
// The booking currency code (if it is defined) has the highest priority, as it is the currency in which the sailor will have to make the payment
export const DEFAULT_PRIORITIES: Readonly<TCurrencySourcePriorities> = {
  [CURRENCY_CODE_SOURCE.invoice]: 100,
  [CURRENCY_CODE_SOURCE.mnvv]: 90,
  [CURRENCY_CODE_SOURCE.fm]: 80,
  [CURRENCY_CODE_SOURCE.store]: 70,
  [CURRENCY_CODE_SOURCE.cookie]: 60,
  [CURRENCY_CODE_SOURCE.url]: 50,
};

export const makeSequence = (priorities?: TOptional<TCurrencySourcePriorities>): CURRENCY_CODE_SOURCE[] =>
  Object.entries({ ...DEFAULT_PRIORITIES, ...priorities })
    .sort(([, a], [, b]) => b - a)
    .map((entry) => entry[0] as CURRENCY_CODE_SOURCE);

export type TUseCurrencyCode = {
  currencyCode: TCurrencyCode;
  currencySymbol: TOptional<string>;
};

// An optional argument can be either a source or a list of priorities to select one from multiple sources

const useCurrencyCode = (source?: TOptional<CURRENCY_CODE_SOURCE | TCurrencySourcePriorities>): TUseCurrencyCode => {
  const { isMNVV } = useMNVV();
  const mnvvCurrency = useAppSelector(selectMnvvCurrencyCode);
  const values: Record<CURRENCY_CODE_SOURCE, TOptional<TCurrencyCode>> = {
    [CURRENCY_CODE_SOURCE.invoice]: useAppSelector(selectInvoiceCurrencyCode),
    [CURRENCY_CODE_SOURCE.store]: useAppSelector(selectCurrencyCode),
    [CURRENCY_CODE_SOURCE.url]: useSearchParams()?.get('currencyCode') as TOptional<TCurrencyCode>,
    [CURRENCY_CODE_SOURCE.fm]:
      getFilterAgencyCurrencyCode() || (getFilterAgentDefaultCurrency() as TOptional<TCurrencyCode>),
    [CURRENCY_CODE_SOURCE.mnvv]: isMNVV ? (mnvvCurrency as TOptional<TCurrencyCode>) : undefined,
    [CURRENCY_CODE_SOURCE.cookie]: useCookieValue<TCurrencyCode>(CURRENCY_CODE_COOKIE),
  };
  const defaultCurrencyCode = useAppSelector(selectDefaultCurrencyCode) as TCurrencyCode;

  const key =
    (source as CURRENCY_CODE_SOURCE) in CURRENCY_CODE_SOURCE
      ? (source as CURRENCY_CODE_SOURCE)
      : makeSequence(source as TOptional<TCurrencySourcePriorities>).find((key) => values[key]);
  const currencyCode: TCurrencyCode = values[key!] || defaultCurrencyCode;

  return useMemo(() => ({ currencyCode, currencySymbol: getSymbolFromCurrenciesData(currencyCode) }), [currencyCode]);
};

export default useCurrencyCode;
