import { createSelector } from '@reduxjs/toolkit';

import { selectMerchandisingBannerDetails } from '@/features/promoBanners/selectors';
import { type RootState, useAppSelector } from '@/store';

const selectBarTab = createSelector(
  [(_, duration: number) => duration, (state: RootState) => selectMerchandisingBannerDetails(state)?.promotionAddons],
  (duration, promotionAddons) =>
    promotionAddons?.find(({ max, min, voyageLengthRestriction }) => {
      if (!max && !min) {
        return duration === parseInt(voyageLengthRestriction || '0', 10);
      }
      return (min == null || duration >= min) && (max == null || duration <= max);
    })?.name,
);

export const useGetBarTabAmount = (duration: number) => useAppSelector((state) => selectBarTab(state, duration));
