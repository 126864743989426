import cn from 'classnames';
import ReactPlayer from 'react-player';

import styles from './Video.module.scss';

type VideoProps = React.ComponentPropsWithoutRef<typeof ReactPlayer> & {
  fillsParentContainer?: boolean;
  isHidden?: boolean;
  /** Poster for the video */
  poster?: string;
  /** URL for the video */
  video: string;
  videoRef?: React.Ref<ReactPlayer>;
};

const Video = ({ fillsParentContainer, isHidden, poster, video, videoRef, ...props }: VideoProps) => (
  <div
    className={cn(styles.root, {
      [styles.fillsParent]: fillsParentContainer,
      [styles.hidden]: isHidden,
    })}
  >
    <ReactPlayer
      className={styles.video}
      config={{ file: { attributes: { poster } } }}
      height="100%"
      ref={videoRef}
      url={video}
      width="100%"
      {...props}
    />
  </div>
);

export default Video;
