import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from '@/helpers/formatted-message';
import { FiltersPriceType } from '@/infra/types/common/filters';

const PriceTypeMessage = ({ isVip = false, priceType }) => {
  let value = '';
  switch (priceType) {
    case 'cabin':
      if (isVip) {
        value = <FormattedMessage defaultMessage=" per suite" id="PriceFootnote.flyout.perSuite" />;
      } else {
        value = <FormattedMessage defaultMessage=" per cabin" id="PriceFootnote.flyout.perCabin" />;
      }
      break;
    case FiltersPriceType.perCabin:
      if (isVip) {
        value = <FormattedMessage defaultMessage=" per suite" id="PriceFootnote.flyout.perSuite" />;
      } else {
        value = (
          <FormattedMessage
            defaultMessage=" per cabin"
            id="PriceFootnote.flyout.perCabin
        "
          />
        );
      }
      break;
    case FiltersPriceType.perSailor:
      value = <FormattedMessage defaultMessage=" per sailor" id="PriceFootnote.flyout.perSailor" />;
      break;
    case FiltersPriceType.sailorPerNight:
      value = (
        <FormattedMessage defaultMessage=" per sailor per night" id="PriceFootNote.cabinModule.perSailorPerNight" />
      );
      break;
    default:
      value = (
        <FormattedMessage
          defaultMessage=" per cabin"
          id="PriceFootnote.flyout.perCabin
      "
        />
      );
      break;
  }
  return value;
};

PriceTypeMessage.propTypes = {
  isVip: PropTypes.bool,
  priceType: PropTypes.string,
};
PriceTypeMessage.defaultProps = {
  isVip: false,
  priceType: '',
};

export default PriceTypeMessage;
