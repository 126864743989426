import Image from 'next/image';
import React, { useRef, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useIntersectionObserver } from 'usehooks-ts';

import { FadeIn } from '@/components/Animations';
import SizzleReelsControls from '@/components/ChooseVoyage/PackageCardDetails/ImageVideo/SizzleReelsControls';
import { Skeleton } from '@/components/Skeleton';
import Video from '@/components/Video';
import { useVWOFlag, VWOFlag } from '@/hooks/useVWOFeature';

import './ImageVideo.scss';

type TImageVideoProps = {
  controls?: boolean;
  hasSizzleReelsControls?: boolean;
  imgSrc: string | undefined;
  isVideoFillsParentContainer?: boolean;
  priority?: boolean;
  skeletonProps?: {
    height: string;
    width: string;
  };
  videoSrc?: string | undefined;
};

const ImageVideo = ({
  controls,
  hasSizzleReelsControls,
  imgSrc = '',
  isVideoFillsParentContainer,
  priority,
  skeletonProps,
  videoSrc = '',
}: TImageVideoProps) => {
  const isSizzleReelsCampaign = useVWOFlag(VWOFlag.SIZZLE_REELS);
  const videoRef = useRef(null);
  const [ref, isInView] = useIntersectionObserver({
    threshold: 0.8,
  });

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isVideoReady, setVideoReady] = useState(false);

  const isVideoEnabled = videoSrc && isSizzleReelsCampaign;
  const sizzleReelsControlsEnabled = hasSizzleReelsControls && isSizzleReelsCampaign;
  const controlsEnabled = controls && !sizzleReelsControlsEnabled;

  const onVideoReady = () => {
    if (isVideoEnabled) {
      setVideoReady(true);
    }
  };

  const isShowImageSkeleton = !(isImageLoaded || isVideoReady) && !isEmpty(skeletonProps);

  return (
    <div className={cn('ImageVideo', { _disableControls: !controlsEnabled })} ref={ref}>
      {isShowImageSkeleton && <Skeleton height={skeletonProps.height} width={skeletonProps.width} />}

      {!isVideoReady && (
        <Image
          alt=""
          className={cn('ImageVideo__imageItem', { ImageVideo__imageItem_hidden: isShowImageSkeleton })}
          fill
          onLoad={() => setIsImageLoaded(true)}
          priority={priority}
          sizes="100vw, 100vh"
          src={imgSrc}
        />
      )}
      {isVideoEnabled && (
        <FadeIn in mountOnEnter timeout={500} unmountOnExit>
          <Video
            controls={controlsEnabled}
            fillsParentContainer={isVideoFillsParentContainer}
            isHidden={!isVideoReady}
            loop
            muted
            onReady={onVideoReady}
            playing={isInView}
            playsinline
            video={videoSrc}
            videoRef={videoRef}
          />
        </FadeIn>
      )}
      {isVideoReady && isVideoEnabled && sizzleReelsControlsEnabled && <SizzleReelsControls videoRef={videoRef} />}
    </div>
  );
};

export default ImageVideo;
